import common from '../common';
import dateHelper from '../helpers/dateHelper';
import { HTTP_METHODS, HTTP_RESPONSE } from '../enums/http';
import { STORE } from '../enums/store';
import { SERVICE_BASIC } from '../enums/serviceBasicTypes';
//import arrayHelper from '../helpers/arrayHelper';

export default class JobService {
    constructor(utils) {
        this.utils = utils;
    }
    formatServiceHistory(item) {
        const job_types = this.utils.$store.state[STORE.SERVICE_SCHEDULE_LOOKUP].job_types;
        const job_categories = this.utils.$store.state[STORE.SERVICE_SCHEDULE_LOOKUP].jtc_categories;
        return {
            ...item,
            date_raised: dateHelper.formatDateReverse(item.date_raised),
            date_completed: dateHelper.formatDateReverse(item.date_completed),
            job_type: job_types.find(y => y.id === item.job_type_id)?.job_type,  
            job_category: job_categories.find(y => y.id === item.lookup_job_category_id)?.category,  
            loading: false,
            documents_loaded: false,
            documents_path: [],
            recharge: !!item.recharge,
            warranty: !!item.warranty,
            mark_up: item.mark_up || "0.00",
            warranty_text: item.warranty ? 'Yes' : 'No',
            supplier_name: item.supplier_name?.supp_name,
            defect: item.defect,
            defect_trimmed: item.defect?.length > 15 ? item.defect.substr(0, 15)+ "\u2026" : item.defect,
            show_more: item.defect?.length > 15
        }
    }
    async getJobTrackers(per_page, vor = false, status_id= null) {
        let url = `/api/getTracker/${per_page}`;
        vor && (url +="?vor=1");
        status_id && (url +=`?status_id=${status_id}`);
        let response = await common.loadAllResults({
            url,
            utils: this.utils
        });
        const suppliers = this.utils.$store.state[STORE.APP].suppliers;
        const job_statuses = this.utils.$store.state[STORE.JOBTRACKER].job_statuses;
        const work_progress_status_list = this.utils.$store.state[STORE.JOBTRACKER_LOOKUP].work_progress_status_list;
        let resp = response.map(y => ({
            ...y,
            status_disp:  job_statuses.find(x=> x.id == y.lookup_job_status_id)?.status,
            disp_work_progress_status:  work_progress_status_list.find(x=> x.id == y.lookup_work_progress_id)?.name,
            job_tracker_id: y.id,
            registration_number: y.vehicle_details?.registration_number,
            job_tracker_po_no: y.po_no,
            document_id: y.document_id,
            job_tracker_po_no_display: y.po_no && process.env.VUE_APP_JOBTRACKER_PO_NO_PREFIX + y.po_no,
            job_tracker_mileage: y.mileage || 0,
            date_raised: dateHelper.formatDateReverse(y.date_raised),
            date_completed: dateHelper.formatDateReverse(y.date_completed),
            supplier_id: y.supplier_id,
            supplier_name: y.supplier_id ? suppliers.find(x => x.id === y.supplier_id)?.supp_name : "",
            job_tracker_next_due_date: dateHelper.formatDateTimeReverse(y.next_due_date),
            past_due: y.next_due_date ? dateHelper.dateDifference(y.next_due_date, dateHelper.calenderFormatedDateTime(dateHelper.getTodaysDateTime())) >= 0 : false,
            servicehistory: y.servicehistory?.map(x => ({
                ...this.formatServiceHistory(x)
            }))
        }));
        return resp;
    }
    async getJobStatuses() {
        let response = await common.getRequest({
            url: `/api/getJobStatus`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response.data;
        } else {
            return [];
        }
    }
    async getJobs(job_id) {
        let response = await common.getRequest({
            url: `/api/getServiceTracker/${job_id}`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            let job = response.data?.data?.find(x => x.job_id == job_id);
            return job ? job.servicehistory?.map(x => ({
                ...this.formatServiceHistory(x)
            })) : [];
        } else {
            return [];
        }
    }
    async getPendingServiceSchedule(filterValue) {
        let url = `/api/getPendingServiceSchedule`;
        filterValue && (url+= `?filterValue=${filterValue}`);
        let response = await common.getRequest({
            url,
            utils: this.utils
        });
        const suppliers = this.utils.$store.state[STORE.APP].suppliers;
        const customers = this.utils.$store.state[STORE.APP].customers;
        const job_types = this.utils.$store.state[STORE.SERVICE_SCHEDULE_LOOKUP].job_types;
        const locations = this.utils.$store.state[STORE.LOCATION_LOOKUP].locations
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response.data?.data?.map(y => {
                let supplier_name = y.supplier_id ? suppliers.find(x => x.id === y.supplier_id)?.supp_name : "";
                let customer_id = y.customer_id;
                let customer = customer_id ? customers.find(x => x.id === customer_id) : "";
                let next_due = y.v_service_basic === SERVICE_BASIC.MILES ? y.v_service_next_due_mile : dateHelper.formatDateReverse(y.v_service_next_due);
                let location = y.lookup_location_id ? locations.find(x => x.id === y.lookup_location_id) : "";
                let expired = false;
                if(y.v_service_basic === SERVICE_BASIC.MILES) {
                    expired = Number.parseInt(y.v_service_next_due_mile || 0) < Number.parseInt(y.mileage || 0);
                } else {
                    expired = (dateHelper.dateDifference(y.v_service_next_due, new Date()) > 0 ? true : false);
                }
                return {
                    ...y,
                    job_type: job_types.find(x => x.id === y.job_type_id)?.job_type,
                    supplier_name,
                    next_due,
                    location: location?.location_disp || "",
                    customer_name: customer?.cust_name || "",
                    expired
                }
            }) || [];
        } else {
            return [];
        }
    }
    async getComments(job_id) {
        let response = await common.getRequest({
            url: `/api/getComment/${job_id}`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response.data?.map(x => ({
                id: x.id,
                time: dateHelper.jsDateTimeFormatter(new Date(x.created_at)),
                user: x.user?.name || 'Unknown', 
                email: x.user?.email || 'No Email', 
                text: x.comment,
            })) || [];
        } else {
            return [];
        }
    }
    async getServiceHistory(vehicle_id, status = null) {
        let response = await common.getRequest({
            url: status ? `/api/getServiceTracker/${vehicle_id}/${status}` : `/api/getServiceTracker/${vehicle_id}`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response.data.data.map(y => ({
                ...y,
                date_of_item: dateHelper.formatDateReverse(y.date_completed),
                last_service_mileage: y.mileage || 0,
                current_miles: y.vehicle_details?.current_miles || 0,
                registration_number: y.vehicle_details?.registration_number,
                job_tracker_po_no_display: y.po_no && process.env.VUE_APP_JOBTRACKER_PO_NO_PREFIX + y.po_no,
                items: y.servicehistory.map(x => ({
                    ...this.formatServiceHistory(x)
                }))
            }));
        } else {
            return [];
        }
    }
    async getJobId() {
        let response = await common.getRequest({
            url: `/api/getJobId`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response.data;
        } else {
            return null;
        }
    }
    async getjobCountsByStatus() {
        let response = await common.getRequest({
            url: `/api/getjobCountsByStatus`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response.data;
        } else {
            return [];
        }
    }
    async saveJobTracker(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            job_id: inputs.job_id,
            lookup_job_status_id: inputs.lookup_job_status_id,
            vehicle_id: inputs.vehicle_id,
            vor: inputs.vor,
            po_no: inputs.po_no,
            lookup_work_progress_id: inputs.lookup_work_progress_id,
            mileage: inputs.mileage,
            supplier_id: inputs.supplier_id,
            document_id: inputs.document_id,
            next_due_date: inputs.vor ? dateHelper.formatDateTime(inputs.next_due_date) : null,     
            date_raised: inputs.date_raised ? dateHelper.formatDateTime(inputs.date_raised) : null,     
            date_completed: inputs.date_completed ? dateHelper.formatDateTime(inputs.date_completed) : null,
            object: {
                vehicle_id: inputs.vehicle_id,
                vehicle_service_schedule_id: inputs.vehicle_service_schedule_id ? [ ...inputs.vehicle_service_schedule_id ] : [],
                mileage: inputs.mileage,
                lookup_job_status_id: inputs.lookup_job_status_id
            }  
        };
        if(method === HTTP_METHODS.POST) {
            payload.last_recorded_miles = inputs.last_recorded_miles;
        }
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/addJobTracker` : `/api/updateJobTracker/${inputs.id}`,
            payload,
            utils: this.utils
        });
    }
    async saveComment({job_tracker_id, comment}) {
        let payload = {
            job_tracker_id,
            comment,
        };
        return await common.saveRequest({
            method:HTTP_METHODS.POST,
            url: `/api/saveComment`,
            payload,
            utils: this.utils
        });
    }
    async addJobTrackerItem({ item, vehicle_id, job_id, job_trackers_id}) {
        let payload = {
            jobs: [
                {
                    vehicle_id,
                    job_id,
                    job_type_id: item.job_type_id,
                    mileage: item.mileage,
                    part_cost: item.part_cost,
                    labour_cost: item.labour_cost,
                    total_cost: isNaN(item.total_cost) ? null :item.total_cost,
                    mark_up: item.mark_up,
                    parts_recharge: item.parts_recharge,
                    labour_recharge: item.labour_recharge,
                    total_recharge: isNaN(item.total_recharge) ? null : item.total_recharge,
                    po_no: item.po_no,
                    lookup_job_category_id: item.lookup_job_category_id,
                    service_item: (item.service_item && item.service_item.length) ? item.service_item.map(y => y.id) : null,
                    driver_name: item.driver_name,
                    driver_contact: item.driver_contact,
                    defect: item.repiar_details,
                    vehicle_service_schedule_id: item.vehicle_service_schedule_id,
                    repiar_details: item.repiar_details,
                    warranty: item.warranty,
                    recharge: item.recharge,
                    vehicle_location: item.vehicle_location,
                    document_id: item.document_id,
                    status: process.env.VUE_APP_DEFAULT_JOB_STATUS
                }
            ]
        };
        return await common.saveRequest({
            method: HTTP_METHODS.POST,
            url: `/api/saveServiceHistory/${job_trackers_id}`,
            payload,
            utils: this.utils
        });
        
    }
    async updateJobTrackerItem(job) {
        let payload = {
            vehicle_id: job.vehicle_id,
            job_type_id: job.job_type_id,
            mileage: job.mileage,
            part_cost: job.part_cost,
            labour_cost: job.labour_cost,
            total_cost: isNaN(job.total_cost) ? null : job.total_cost,
            parts_recharge: job.parts_recharge,
            mark_up: job.mark_up,
            labour_recharge: job.labour_recharge,
            total_recharge: isNaN(job.total_recharge) ? null : job.total_recharge,
            po_no: job.po_no,
            lookup_job_category_id: job.lookup_job_category_id,
            service_item: (job.service_item && job.service_item.length) ? job.service_item.map(y => y.id) : null,
            driver_name: job.driver_name,
            driver_contact: job.driver_contact,
            defect: job.defect,
            repiar_details: job.repiar_details,
            warranty: job.warranty,
            recharge: job.recharge,
            document_id: job.document_id,
            vehicle_location: job.vehicle_location,
        };
        return await common.saveRequest({
            method: HTTP_METHODS.PUT,
            url: `/api/updateServiceHistory/${job.id}`,
            payload,
            utils: this.utils
        });
    }
    async saveWorkProgressAudit(payload) {
        return await common.saveRequest({
            method: HTTP_METHODS.POST,
            url: `/api/saveWorkProgressAudit`,
            payload,
            utils: this.utils
        });
    }
    async deleteJobTracker(id, suppress_error = false) {
        return await common.deleteRequest({
            url: `/api/deleteJobTracker/${id}`,
            utils: this.utils,
            suppress_error
        });
    }
    async deleteJobTrackerItem(id) {
        return await common.deleteRequest({
            url: `/api/removeServiceHistory/${id}`,
            utils: this.utils
        });
    }
}