export const VEHICLE = {
    ADD: "add_vehicle",
    DELETE: "delete_vehicle",
    EDIT: "edit_vehicle",
    VIEW: "view_vehicle",
    VIEW_DOC: "view_vehicle_doc",
    UPLOAD_DOC: "upload_vehicle_doc",
    DELETE_DOC: "delete_vehicle_doc",
};
export const JOB_TRACKER = {
    ADD: "add_jobtracker",
    DELETE: "delete_jobtracker",
    EDIT: "edit_jobtracker",
    VIEW: "view_jobtracker",
    VIEW_DOC: "view_jobtracker_doc",
    UPLOAD_DOC: "upload_jobtracker_doc",
    DELETE_DOC: "delete_jobtracker_doc",
    REOPEN_CLOSED_JOBS: "reopen_closed_jobs"
};
export const REPORTS = {
    JOB_TRACKER_REPORT: "report",
};
export const CUSTOMER = {
    ADD: "add_customer",
    DELETE: "delete_customer",
    EDIT: "edit_customer",
    VIEW: "view_customer",
};
export const SUPPLIER = {
    ADD: "add_supplier",
    DELETE: "delete_supplier",
    EDIT: "edit_supplier",
    VIEW: "view_supplier",
};
export const LOOKUPS = {
    ADD: "add_lookup",
    DELETE: "delete_lookup",
    EDIT: "edit_lookup",
    VIEW: "view_lookup",
}
export const USER = {
    ADD: "add_user",
    DELETE: "delete_user",
    EDIT: "edit_user",
    VIEW: "view_user",
}
export const ROLES = {
    ADD: "add_role",
    DELETE: "delete_role",
    EDIT: "edit_role",
    VIEW: "view_role",
}
export const MILEAGE_UPLOAD = {
    UPLOAD: "upload_file",
}