import { VEHICLE, CUSTOMER, LOOKUPS, SUPPLIER, USER, ROLES, JOB_TRACKER, REPORTS, MILEAGE_UPLOAD } from './enums/permissions'
export default [
    { title: "Dashboard", icon: "dashboard.png", to: "/dashboard" },
    { title: "Vehicles", icon: "vehicle.png", to: "/vehicles", permissions: VEHICLE },
    { title: "Mileage Upload", icon: "speedometer.png", to: "/mileage-upload", permissions: MILEAGE_UPLOAD },
    { title: "Job Tracker", icon: "issues.png", to: "/jobtracker", permissions: JOB_TRACKER },
    { title: "Incident Management", icon: "incident.png", to: "/incidents", permissions: JOB_TRACKER },
    { 
        title: "Reports", 
        icon: "reports.png", 
        items: [
            {
                title: "Job Tracker Report",
                icon: "issues.png",
                to: "/reports/job-tracker-report",
                permissions: REPORTS,
            }
        ]
    },
    {
        title: "Settings",
        icon: "settings.png",
        items: [
            {
                title: "Roles",
                icon: "roles.png",
                to: "/settings/roles",
                permissions: ROLES,
            },
            {
                title: "Users",
                icon: "users.png",
                to: "/settings/users",
                permissions: USER,
            },
            {
                title: "Customers",
                icon: "customer.png",
                to: "/settings/customers",
                permissions: CUSTOMER
            },
            {
                title: "Suppliers",
                icon: "supplier.png",
                to: "/settings/suppliers",
                permissions: SUPPLIER
            },
            {
                title: "Lookups",
                icon: "database.png",
                to: "/settings/lookups/equipments",
                permissions: LOOKUPS,
                items: [
                    {
                        title: "Vehicles",
                        icon: "vehicle.png",
                        to: "/settings/lookups/vehicles"
                    },
                    {
                        title: "Job Tracker",
                        icon: "issues.png",
                        to: "/settings/lookups/jobtracker"
                    },
                    {
                        title: "Conversions",
                        icon: "conversion.png",
                        to: "/settings/lookups/conversion"
                    },
                    {
                        title: "Equipments",
                        icon: "equipments.png",
                        to: "/settings/lookups/equipments"
                    },
                    {
                        title: "Contracts",
                        icon: "contract.png",
                        to: "/settings/lookups/contract"
                    },
                    {
                        title: "Service schedule",
                        icon: "services.png",
                        to: "/settings/lookups/serviceschedule"
                    },
                    {
                        title: "Financials",
                        icon: "financial.png",
                        to: "/settings/lookups/financials"
                    },
                    {
                        title: "Locations",
                        icon: "address.png",
                        to: "/settings/lookups/locations"
                    },
                ]
            },

        ],
    },
];